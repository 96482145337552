<template>
  <div id="add-blog">
    <h1 class="showtit">编辑文章</h1>
    <hr/>
    <form>
      <a-input style="margin-top: 15px" size="large" addon-before="标题:" v-model="blog.posts_name"/>

      <a-input style="margin-top: 15px" size="large" addon-before="权重:" v-model="blog.index"/>

      <div style="display: flex;margin-top: 15px;">
        <label
            class="cssca"
            style="
            margin-bottom: 20px;
            width: 200px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          点我上传标题图<input
            type="file"
            style="display: none"
            @change="title_img"
            accept="image/*"
        /></label>
        <div
            style="
            border-radius: 10px;
            box-shadow: 1px 1px 1px 1px rgba(128, 128, 128, 0.26);
            width: 168px;
            height: 112px;
            margin-left: 50px;
            font-size: 18px;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <img :src="blog.posts_image" title="标题效果图" width="100%" height="100%s"/>
        </div>
      </div>

      <label>文章内容</label>

      <we v-model="blog.posts_content" :isClear="false"></we>

      <div style="display: flex;justify-content: space-between;align-items: center">

        <button
            class="add cssca"
            type="button"
            style="background: #e83202"
            @click.prevent="submit(id)"
        >
          修改文章
        </button>
        <a-button class="editbt cssca" type="danger" @click="del(id)"
        >移除此篇文章
        </a-button
        >
      </div>
    </form>

    <div id="preview">
      <h2>文章预览</h2>
      <hr/>
      <p>文章标题：{{ blog.posts_name }}</p>
      <hr class="ad" noshade color="#CCCCCC"/>
      <p style="word-break: break-all" v-html="blog.posts_content"></p>
    </div>
  </div>
</template>

<script>
import we from '@/components/WANG'

export default {
  components: {
    we
  },
  name: "editBlog",
  data() {
    return {
      id: this.$route.params.id,
      blog: {
        posts_image: '/public/img/tubiao.png',
      },
    };
  },
  methods: {
    title_img(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.blog.title_image = reader.result;

        this.$axios.post(
            "/Admin/Api/Posts/Base64ToImage",
            {
              base64: this.blog.title_image,
            }
        )
            .then((value) => {
              if (value.data.code === 1) {
                this.blog.posts_image = JSON.parse(value.data.data) + "";
                this.openType("success", value.data.code, value.data.msg);
              } else {
                this.openType("error", value.data.code, value.data.msg);
              }
            });
      };
    },
    del(v) {
      this.$axios.post(
          `/Admin/Api/Posts/DelPosts/${v}`
      )
          .then((value) => {
            this.openType("success", value.data.code, value.data.msg);
            setTimeout(() => {
              this.$router.push("/BackEnd/article/ArticleList");
            }, 700);

          }).catch(er => {
        this.openType("error", er.code, er.msg);
      })
    },

    submit(v) {
      this.$axios.post(
          "/Admin/Api/Posts/ChangePosts/" + v,
          {
            posts_name: this.blog.posts_name,
            posts_content: this.blog.posts_content,
            posts_image: this.blog.posts_image,
            index: this.blog.index,
          }
      )
          .then((value) => {
            this.openType("success", value.data.code, value.data.msg);
            setTimeout(() => {
              this.$router.push("/BackEnd/article/ArticleList");
            }, 700);

          }).catch(err => {
        this.openType('error', err.msg, err.data)

      })
    },


    openType(type, code, msg) {
      this.$notification[type]({
        message: "code: " + code,
        description: msg,
      });
    },
  },
  created() {
    const metas = document.getElementsByTagName("meta");
    metas[4].content = "width=device-width,initial-scale=0.38";
    this.$axios.post(
        `/Admin/Api/Posts/GetPosts/${this.$route.params.id}`,
    )
        .then((value) => {
          this.blog = JSON.parse(value.data.data);
        });
  },
};
</script>

<style scoped>
#edit-blog * {
  box-sizing: border-box;
}

#edit-blog {
  margin: 20px auto;
  max-width: 80%;
  padding: 20px;
}

label {
  display: block;
  margin: 20px 0 10px;
}

input[type="text"],
textarea {
  display: block;
  width: 100%;
  padding: 8px;
}

input[type="checkbox"] {
  width: 20px;
  margin-right: 40px;
  margin-top: 20px;
}

textarea {
  height: 200px;
}

#checkboxes label {
  display: inline-block;
  margin-top: 0;
}

#checkboxes input {
  display: inline-block;
  margin-left: 10px;
}

button {
  display: block;
  margin: 20px 0;
  background: crimson;
  color: #fff;
  border: 0;
  padding: 14px;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
}

#preview {
  border-radius: 19px;
  padding: 10px 20px;
  border: 1px dotted #ccc;
  margin: 15px 0;
  box-shadow: 2.5px 3px 5px 3px rgba(43, 44, 31, 0.2);
}

h2 {
  margin-top: 10px;
}

.editbt {

  font-size: 18px;
  width: auto;
  padding: 10px;
  height: auto;
  border-radius: 15px;
}

.bj {
  margin: 5px 0;
}
</style>
